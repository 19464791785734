import { css, Global } from '@emotion/react'
import {
  canNavigate,
  getStepProgress,
  isLastStep,
  isStepSingleClick,
  subscriptionBreadcrumbsTranslationKeys,
  subscriptionUiSteps,
  subscriptionUiStepsById,
  type SubscriptionUiStep,
} from '@orus.eu/dimensions'
import {
  borderRadius,
  Button,
  mobileMediaQuery,
  shadow,
  spacing,
  Text,
  useScreenType,
  useTranslate,
} from '@orus.eu/pharaoh'
import { memo, useMemo, useRef } from 'react'
import { useEmbeddingPartnerConfiguration } from '../../../lib/embedding-partner'
import { useNavigateBack } from '../../../lib/hooks/use-navigate-back'
import { GoBackButton, GoBackButtonWithContracts } from './components/go-back-button'

import styled from '@emotion/styled'
import { useSearch } from '@tanstack/react-router'
import { useInView } from 'react-intersection-observer'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { useSession } from '../../../lib/session'
import { SubscriptionStepHeaderLayout, SubscriptionStepLayout } from './components/subscription-step-layout'
import {
  subscriptionStepOuterElementCss,
  subscriptionStepOuterElementCssMobile,
} from './components/subscription-step-outer-element-style'
import { SubscriptionV2Breadcrumbs } from './components/subscription-v2-breadcrumbs'
import { SubscriptionV2Header } from './components/subscription-v2-header'
import { SubscriptionElementGroupBlock, SubscriptionElementWrapper } from './subscription-v2-element-group-block'
import type { CommonSubscriptionStepProps } from './subscription-v2-props'
import { SubscriptionValidationIssueBlock } from './subscription-v2-validation-issue'

type SubscriptionV2StepPageContentProps = CommonSubscriptionStepProps & {
  step: SubscriptionUiStep
}

/**
 * This component is responsible for rendenring the content of a subscriptions step page.
 * Also see SubscriptionV2StepPageLoader for state management
 */
export const SubscriptionV2StepPageContent = memo<SubscriptionV2StepPageContentProps>(
  function SubscriptionV2StepPageContent(props) {
    const translate = useTranslate()
    const urlSearchParams = useSearch({ strict: false })
    const embeddingPartnerConfiguration = useEmbeddingPartnerConfiguration()
    const screenType = useScreenType()
    const navigateToContracts = useNavigateTo({ to: '/contracts' })
    const navigateBack = useNavigateBack()
    const { step, ...passedProps } = props
    const progress = getStepProgress(step.id)
    const isFirstStep = useMemo(() => subscriptionUiSteps[0].id === step.id, [step.id])
    const { user } = useSession()
    const canGoBack = useMemo(() => canNavigate(step.id), [step.id])
    const detailElements = props.detail && step.details ? step.details[props.detail.type] : undefined

    const compact = progress >= 1

    if (urlSearchParams.embedInIframe != null) {
      return props.localState ? (
        <>
          <Global
            styles={css`
              :root {
                /* stylelint-disable-next-line selector-id-pattern */
                #axeptio_overlay {
                  display: none;
                }
              }
            `}
          />
          <SubscriptionElementGroupBlock group={step.bodyElements} {...passedProps} />
        </>
      ) : undefined
    }

    let pageTitleText: string | undefined = undefined
    if (progress >= 1 && screenType === 'mobile') {
      const breadcrumb = subscriptionUiStepsById[step.id].breadcrumbs[1]
      if (breadcrumb) {
        pageTitleText = translate(subscriptionBreadcrumbsTranslationKeys[breadcrumb])
      }
    }

    const header = (
      <SubscriptionStepHeaderLayout progress={progress} compact={compact}>
        <SubscriptionV2Header title={pageTitleText} />
      </SubscriptionStepHeaderLayout>
    )

    if (detailElements) {
      return (
        <div css={screenType === 'mobile' ? subscriptionStepOuterElementCssMobile : subscriptionStepOuterElementCss}>
          {header}
          <SubscriptionStepLayout
            left={canGoBack ? <GoBackButton goBackToPreviousStep={navigateBack} /> : null}
            center={<SubscriptionElementGroupBlock group={detailElements} {...passedProps} />}
            right={<></>}
            compact={compact}
          />
        </div>
      )
    }

    const sideElements =
      step.sideElements && (screenType === 'desktop' || step.sideElementsMobileVariant !== 'hidden')
        ? step.sideElements
        : undefined

    const stepSubtitle = step.subtitleElement

    const embeddingPartnerStepSubtitle = embeddingPartnerConfiguration?.partnerStepSubtitles?.[step.id]

    const title = step.title ?? (step.titleKey ? translate(step.titleKey) : undefined)
    const stepContent = (
      <>
        {header}
        <SubscriptionStepLayout
          left={
            <>
              {user ? (
                <GoBackButtonWithContracts
                  isBackButtonHidden={isFirstStep}
                  goBackToPreviousStep={props.goBackToPreviousStep}
                  goBackContracts={navigateToContracts}
                />
              ) : isFirstStep || !canGoBack ? (
                <div
                  css={css`
                    height: 40px;
                  `}
                />
              ) : (
                <GoBackButton goBackToPreviousStep={props.goBackToPreviousStep} />
              )}
              <SubscriptionV2Breadcrumbs
                css={css`
                  margin-top: ${spacing[90]};
                `}
                stepId={step.id}
                goBackToBreadcrumbRootStep={props.goBackToBreadcrumbRootStep}
              />
            </>
          }
          center={
            <>
              {title ? (
                <div
                  css={css`
                    margin-bottom: ${spacing[70]};
                    width: 100%;
                    max-width: 760px;
                  `}
                >
                  <Text variant="h5">{title}</Text>

                  {stepSubtitle ? (
                    <SubscriptionElementWrapper verticalMargins={0} uiElement={stepSubtitle} {...passedProps} />
                  ) : (
                    <></>
                  )}

                  {embeddingPartnerStepSubtitle ? (
                    <Text
                      css={css`
                        margin-top: ${spacing[50]};
                      `}
                      variant="body1"
                    >
                      {embeddingPartnerStepSubtitle}
                    </Text>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              <SubscriptionValidationIssueBlock issue={props.serverValidationIssue} />
              {props.localState ? (
                <>
                  <SubscriptionElementGroupBlock group={step.bodyElements} {...passedProps} />
                  <BottomNavigationButtons {...props} isFirstStep={isFirstStep} />
                </>
              ) : (
                <></>
              )}
            </>
          }
          right={
            sideElements ? (
              <SubscriptionElementGroupBlock
                css={
                  screenType === 'mobile' && step.sideElementsMobileVariant === 'card'
                    ? css`
                        border-radius: ${borderRadius[30]};
                        box-shadow: ${shadow.top[30]};
                        margin: -${spacing[60]};
                        padding: ${spacing[60]};
                      `
                    : screenType === 'desktop'
                      ? css`
                          margin-top: ${spacing[80]};
                        `
                      : undefined
                }
                group={sideElements}
                {...passedProps}
              />
            ) : undefined
          }
          compact={compact}
        />
      </>
    )

    return isLastStep(step.id) ? (
      <div css={subscriptionStepOuterElementCss}>{stepContent}</div>
    ) : (
      <form
        id={`all-subscriptions-${step.id}`}
        onSubmit={props.isLoadingWhileTryCompleteStep ? undefined : props.handleSubmit}
        css={screenType === 'desktop' ? subscriptionStepOuterElementCss : subscriptionStepOuterElementCssMobile}
      >
        {stepContent}
      </form>
    )
  },
)

const BottomNavigationButtons = memo<SubscriptionV2StepPageContentProps & { isFirstStep: boolean; className?: string }>(
  function BottomNavigationButtons(props) {
    const { step, ...passedProps } = props
    const { nextEnabled, goBackToPreviousStep, isLoadingWhileTryCompleteStep, isFirstStep, className } = passedProps
    const translate = useTranslate()
    const buttonRowRef = useRef<HTMLDivElement>(null)
    const { ref: triggerRef, inView } = useInView({
      threshold: 1,
      onChange: (inView) => buttonRowRef.current?.classList?.toggle?.('is-pinned', !inView),
    })

    const isDesktop = useScreenType() === 'desktop'

    const showJumpToBottom = !!step.jumpToBottom
    const showNextStepLabel = step.nextStepLabelTip !== undefined
    const showNextStepButton = !isStepSingleClick[step.id] && canNavigate(step.id)
    const showPreviousStepButton = !isFirstStep && !isDesktop && canNavigate(step.id)

    if (!showNextStepLabel && !showNextStepButton && !showPreviousStepButton) return null

    return (
      <>
        {showJumpToBottom && !inView && (
          <FloatingButton icon="angle-down-solid" onClick={() => buttonRowRef.current?.scrollIntoView?.()} />
        )}
        <ButtonRowDiv className={className} ref={buttonRowRef}>
          <div ref={triggerRef}></div>
          {showNextStepLabel && <Text variant="body2">{step.nextStepLabelTip}</Text>}
          {step.secondaryNavigationUiElement ? (
            <SubscriptionElementWrapper
              verticalMargins={0}
              uiElement={step.secondaryNavigationUiElement}
              {...passedProps}
            />
          ) : (
            <></>
          )}
          {showNextStepButton && (
            <Button
              type="submit"
              disabled={!nextEnabled}
              fullWidth={!isDesktop}
              size={!isDesktop ? 'large' : 'medium'}
              isLoading={isLoadingWhileTryCompleteStep}
              className={!showJumpToBottom ? 'next-button' : undefined}
            >
              {translate(step.nextStepLabelKey ?? 'continue')}
            </Button>
          )}
        </ButtonRowDiv>
        {showPreviousStepButton && (
          <Button
            variant="tertiary"
            fullWidth
            size="large"
            icon="arrow-left-regular"
            avatarPosition="left"
            onClick={goBackToPreviousStep}
            className="previous-button"
          >
            {translate('back')}
          </Button>
        )}
      </>
    )
  },
)

const FloatingButton = styled(Button)`
  position: fixed;
  bottom: ${spacing[60]};
  right: ${spacing[50]};
  z-index: 1000;
`

const ButtonRowDiv = styled.div`
  display: flex;
  gap: ${spacing[50]};
  width: 100%;
  max-width: 760px;
  margin-top: ${spacing[70]};
  margin-bottom: ${spacing[50]};
  flex-direction: row;
  justify-content: flex-end;

  &.is-pinned .next-button {
    &:not(:disabled) {
      position: fixed;
      bottom: ${spacing[60]};
      right: ${spacing[50]};
      z-index: 1000;
    }
  }

  ${mobileMediaQuery} {
    flex-direction: column;

    max-width: 100%;

    &.is-pinned .next-button {
      &:not(:disabled) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        border-radius: 0;
      }
    }
  }
`
